<template>
  <VRow justify="space-between" class="my-1">
    <BreadCrumb />
    <div class="d-flex flex-row align-items-center justify-content-between">
      <div
        class="d-flex flex-column align-items-center justify-center align-center"
      >
        <h2>
          {{ rangeTitle }}
        </h2>
        <Datepicker v-on:datesUpdated="getOrdersByDate" />
      </div>
    </div>
    <n-button ghost @click="syncOrders">
      <template #icon>
        <n-icon size="20">
          <RefreshIcon />
        </n-icon>
      </template>
    </n-button>
  </VRow>
  <VRow v-if="venue">
    <VCol cols="12">
      <OrderAnalytics
        :ordered-offers="{
          count: Object.values(offerOrders).filter(
            (order) => order.offer.type === OfferType.OFFER
          ).length,
          totalValue: offersTotalPrice,
        }"
        :ordered-suggestions="{
          count: Object.values(offerOrders).filter(
            (order) => order.offer.type === OfferType.SUGGESTION
          ).length,
          totalValue: suggestionsTotalPrice,
        }"
        :ordered-treats="{
          count: Object.values(treatOrders).length,
          totalValue: treatsTotalPrice,
        }"
        :total-orders="{
          count: Object.values(mixedOrders).length,
          totalValue: totalPrice,
        }"
      />
    </VCol>
  </VRow>
  <VRow v-if="venue">
    <VCol class="d-flex flex-column gap-y-3">
      <div class="d-flex justify-end">
        <DownloadReportButton :from-date="fromDate" :to-date="toDate" />
      </div>
      <VCard>
        <VCardText>
          <VTable>
            <thead>
              <tr>
                <th><div class="header">Drink</div></th>
                <th><div class="header">Receiver</div></th>
                <th><div class="header">Date</div></th>
                <th><div class="header">Time</div></th>
                <th><div class="header">Price</div></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="order in Object.values(mixedOrders).reverse()"
                v-bind:key="`${order.type}-${order.id}`"
              >
                <td>1 × {{ order.name }}</td>
                <td style="max-width: 200px">{{ order.user }}</td>
                <td style="min-width: 100px">
                  <DateFormatter :date="order.time" />
                </td>
                <td style="min-width: 100px">
                  <TimeFormatter :date="order.time" />
                </td>
                <td>
                  <span v-if="order.price">
                    <PriceFormatter
                      :amount="order.price"
                      :currency="order.currency"
                      :key="`${order.type}-${order.id}`"
                    />
                  </span>
                  <span v-else>--</span>
                </td>
              </tr>
            </tbody>
          </VTable>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style scoped>
.top_banner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 20px;
}
td {
  text-align: center;
}
th {
  width: 12.5%;
  min-width: 90px;
  text-align: center;
}
.header {
  text-align: center;
  font-weight: bold;
}
.acknowledged {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 5px 0;
  justify-content: center;
  align-items: center;
  width: fit-content;
}
</style>

<script setup lang="ts">
import BreadCrumb from "@/components/BreadCrumb.vue";
import { useTreatOrderStore } from "@/stores/orderStore";
import type { User } from "@/types/Messaging";
import { NButton, NIcon } from "naive-ui";
import type {
  MixedOrder,
  OfferOrder,
  OfferOrder as OfferOrderType,
  TreatOrder as TreatOrderType,
  VenueType,
} from "@/types/Venue";
import { OrderType } from "@/types/Venue";
import { Refresh as RefreshIcon } from "@vicons/ionicons5";
import { onMounted, ref, watch } from "vue";
import Datepicker from "@/components/Datepicker.vue";
import { OfferType } from "@/views/offers/types/Offers";
import OrderAnalytics from "@/views/orders/components/OrderAnalytics.vue";
import PriceFormatter from "@/components/PriceFormatter.vue";
import DateFormatter from "@/components/DateFormatter.vue";
import { useVenueStore } from "@/stores/venueStore";
import TimeFormatter from "@/components/TimeFormatter.vue";
import { NewVenueService } from "@/services/newVenueService";
import { currentMonthFirstDay } from "@/common/dates";
import DownloadReportButton from "@/views/orders/components/DownloadReportButton.vue";

const offerOrders = ref({} as Record<string, OfferOrder>);
const treatOrders = ref({} as Record<string, TreatOrderType>);
const mixedOrders = ref({} as Record<string, MixedOrder>);
const venue = ref({} as VenueType);
const venueStore = useVenueStore();
const orderedTreatStore = useTreatOrderStore();
const treatsTotalPrice = ref(0);
const offersTotalPrice = ref(0);
const suggestionsTotalPrice = ref(0);
const totalPrice = ref(0);
const fromDate = ref(null as Date | null);
const toDate = ref(null as Date | null);
const rangeTitle = ref("Custom range");

function createUsername(user: User): string {
  let username = user.username || "";
  if (user.firstname && user.lastname)
    username = `${user.firstname} ${user.lastname.charAt(0).toUpperCase()}.`;
  return username;
}

const constructMixedOrders = () => {
  resetValues();

  for (const offerOrder of Object.values(offerOrders.value)) {
    const order = offerOrderToMixedOrder(offerOrder);
    mixedOrders.value[`${OrderType.OFFER}-${offerOrder.id}`] = order;

    if (order.acknowledged) {
      if (order.type == OfferType.OFFER)
        offersTotalPrice.value += order.price || 0;
      else suggestionsTotalPrice.value += order.price || 0;

      totalPrice.value += order.price || 0;
    }
  }

  for (const treatOrder of Object.values(treatOrders.value)) {
    const order = treatOrderToMixedOrder(treatOrder);
    mixedOrders.value[`${OrderType.TREAT}-${treatOrder.id}`] = order;

    treatsTotalPrice.value += order.price || 0;
    totalPrice.value += order.price || 0;
  }

  sortMixedOrder();
};

const resetValues = () => {
  mixedOrders.value = {};
  offersTotalPrice.value = 0;
  treatsTotalPrice.value = 0;
  suggestionsTotalPrice.value = 0;
  totalPrice.value = 0;
};

const offerOrderToMixedOrder = (offerOrder: OfferOrderType): MixedOrder => ({
  id: offerOrder.id,
  table: offerOrder.checkIn.qr_code
    ? `${offerOrder.checkIn.qr_code.section} ${offerOrder.checkIn.qr_code.name}`
    : null,
  type: offerOrder.offer.type,
  user: createUsername(offerOrder.checkIn.user),
  name: `${offerOrder.quantity} x ${offerOrder.offer.title}`,
  price: constructOrderOfferPrice(offerOrder),
  currency: offerOrder.currency,
  time: new Date(offerOrder.createdAt),
  acknowledged: offerOrder.acknowledged,
  acknowledgedBy: offerOrder.acknowledgedBy,
  acknowledgedAt: offerOrder.acknowledgedAt,
  dismissed: offerOrder.dismissed,
  dismissedBy: offerOrder.dismissedBy,
  dismissedAt: offerOrder.dismissedAt,
});

const constructOrderOfferPrice = (
  offerOrder: OfferOrderType
): number | undefined => {
  if (offerOrder.totalPrice) return offerOrder.totalPrice;

  return offerOrder.offer.discount && offerOrder.offer.discount.price_new
    ? Number(offerOrder.quantity) * offerOrder.offer.discount.price_new
    : undefined;
};

const treatOrderToMixedOrder = (treatOrder: TreatOrderType): MixedOrder => ({
  id: treatOrder.id,
  table: treatOrder.receiverUserCheckIn?.qrcode
    ? `${treatOrder.receiverUserCheckIn.qrcode.section} ${treatOrder.receiverUserCheckIn.qrcode.name}`
    : null,
  type: OrderType.TREAT,
  user: `${
    treatOrder.receiverUserCheckIn
      ? treatOrder.receiverUserCheckIn.user.username
      : "Invited User"
  } `,
  name: `${treatOrder.description}`,
  price: treatOrder.price,
  currency: treatOrder.currency,
  time: new Date(treatOrder.updatedAt),
  acknowledged: treatOrder.acknowledged,
  acknowledgedBy: treatOrder.acknowledgedBy,
  acknowledgedAt: treatOrder.acknowledgedAt,
});

const sortMixedOrder = () => {
  const recordArray = Object.entries(mixedOrders.value);
  recordArray.sort((a, b) => a[1].time.getTime() - b[1].time.getTime());

  mixedOrders.value = Object.fromEntries(recordArray);
};

const syncFromStore = () => {
  treatOrders.value = orderedTreatStore.getAllOrders();
};

const syncOrders = async () => {
  const dates = {
    fromDate: fromDate.value ? fromDate.value.toISOString() : null,
    toDate: toDate.value ? toDate.value.toISOString() : null,
  };
  await orderedTreatStore.syncOrderedTreats(dates);
  syncFromStore();
  constructMixedOrders();
};

const getOrdersByDate = (dates: {
  fromDate: Date | null;
  toDate: Date | null;
  rangeTitle: string;
}) => {
  fromDate.value = dates.fromDate;
  toDate.value = dates.toDate;
  rangeTitle.value = dates.rangeTitle;
  syncOrders();
};

watch(
  () => [orderedTreatStore.getNotAcknowledgedLength()],
  (newCount, oldCount) => {
    if (newCount > oldCount) {
      syncFromStore();
      constructMixedOrders();
    }
  }
);

onMounted(async () => {
  syncFromStore();
  constructMixedOrders();
  venue.value = await venueStore.getVenue();
});
</script>
